.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
      background-color: #fff !important;
      color: #198d58;
      border-bottom: 1px solid #198d58;
}

.kyoga-MuiSvgIcon-root {
  color: #198d58 !important;
}


.kyoga-header-text {
  color: #0b9a3b !important;
}

.kyoga-cards-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: auto;
}

.MuiPaper-root {
  border-radius: 0px;
  box-shadow: 0px 0px 0px #fff !important;
  /* border-bottom: 1px dotted #ccc; */
}

.kyoga-MuiPaper-root{
  border-bottom: 1px dotted #ccc;
}

.css-17dcqu8-MuiTypography-root-MuiLink-root {
  color: #198d58 !important; 
}

.kyoga-button {
  background-color: #c8e1cc !important; 
}


.kyoga-outline-button {
  border: 1px solid #198d58 !important; 
  color: #198d58 !important; 
}

.kyoga-link{
  color: #198d58 !important; 
  text-decoration-color: none !important; 
  
}

.kyoga-link > a:link {
  text-decoration-color: #198d58 !important; 
}

.css-6bxval {
  padding-top: 64px;
  padding-bottom: 0 !important;
}

.kyoga-align-right {
  text-align: justify;
  float: right;
}

.css-ah0e1x-MuiPaper-root {
  border-radius: 0px;
  box-shadow: none;
}

img {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.kyogaH1 {
    font-size: calc((2.2 - 1) * 1.2vw + 1rem);
    line-height: calc(1.4 * (1 + (1 - 2.2)/25));
    font-weight: 400;
    text-transform: none;
    position: relative;
    padding-top: 15px;
    color: #198d58;
    
}

.kyogaDiv {
  font-family: "Cormorant Garamond", Sans-serif;
  font-size: 26px;
  font-weight: 320;
  width: 100%;
  color: #2d2a2a;
}

.landingImg {
  width: 60vh;
  border-radius: 8px;
}

.css-1nmcc6o {
  padding-top: 0 !important;
}

.nav-menu {
  left: 0px !important;
  width: 280px;
  height: 264px;
}

@media screen and ( max-width:768px) {
  .kyoga-cards-list {
    display: block;
    flex-wrap: nowrap;
    gap: 20px;
    overflow-x: auto;
  }
 
  .css-ah0e1x-MuiPaper-root {
      height: 70px !important;
  }

  .css-x6ilej {
    padding-top: 0px !important;
  }

  .kyoga-service-title {
    margin-bottom: 1px !important;
  }

  .landingImg {
    width: 100%;
  }


  .contact--form{
    width: 360px;
  }


  .kyogaDiv {
    font-family: "Cormorant Garamond", Sans-serif;
    font-size: 20px;
    font-weight: 320;
    width: 100%;
    color: #2d2a2a;
    
    padding-right: 0px !important;
  }
  .css-h93ljk-MuiTypography-root {
    font-size: 20px !important;
  }
  
  .next-line{
    display: block;
  }
}

